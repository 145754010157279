.appbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #000; /* Dark background color */
  padding: 1rem;
  color: white; /* White text color */
  position: fixed; /* Fix the app bar at the top */
  top: 0; /* Position it at the top */
  left: 0;
  right: 0;
  z-index: 1000; /* Ensure it stays on top of other content */
}

.appbar h1 {
  margin: 0;
  font-size: 1.5rem;
}

.appbar nav {
  display: flex;
  gap: 1rem;
}

.appbar a {
  color: white; /* White text color */
  text-decoration: none;
  font-size: 1rem;
}

.appbar a:hover {
  text-decoration: underline;
}
